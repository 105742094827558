
import { Component, Ref, Vue } from "vue-property-decorator";
import AttachmentsView from "@/components/AttachmentsView/index.vue";
import ExportWord from "@/components/DownloadWord/index.vue";
import AuditButtons from "@/components/AuditButtons/index.vue";
import { AuditBodyDto } from "@/utils/customDto";
import {
  InternalMonthlySummarizeDto,
  OrganizationUnitDto,
  ExternalProjectProcessMonitoringDto,
  AttachmentHostType,
  AuditFlowScope
} from "@/api/appService";
import api from "@/api";
import moment from "moment";
@Component({
  name: "externalProjectProcessMonitoringDetail",
  components: {
    AttachmentsView,
    ExportWord,
    AuditButtons
  }
})
export default class externalProjectProcessMonitoringDetail extends Vue {
  @Ref() auditButtons!: AuditButtons;
  recordId?: number;
  detail: ExternalProjectProcessMonitoringDto = {};
  selector: string = "table-detail";
  filename: string = "项目监测记录表";
  styles: string = "";

  partnerOrgList: OrganizationUnitDto[] = [
    {
      displayName: "合作方1",
      id: 1
    },
    {
      displayName: "合作方2",
      id: 2
    },
    {
      displayName: "合作方3",
      id: 3
    },
    {
      displayName: "合作方4",
      id: 4
    }
  ];

  auditParams: AuditBodyDto = {
    hostId: undefined,
    hostType: AuditFlowScope.ExternalProjectProcessMonitoring,
    auditFlowId: undefined,
    auditStatus: undefined,
    audit: undefined
  };

  get hostTypeFile() {
    return AttachmentHostType.External_Project_Process_Monitoring;
  }
  created() {
    if (this.$route.params.id) {
      this.recordId = Number(this.$route.params.id);
      this.fetchDetail();
    }
  }

  fetchData() {
    return api.externalProjectProcessMonitoringService.audit;
  }

  // 获取详情
  async fetchDetail() {
    await api.externalProjectProcessMonitoringService
      .getDetail({ id: this.recordId })
      .then(res => {
        let resData = res;
        this.detail = { ...resData };
        this.auditParams!.hostId = res.id + "";
        this.auditParams!.auditFlowId = res.auditFlowId;
        this.auditParams!.auditStatus = res.auditStatus;
        this.auditParams!.audit = res.audit;
        if (res.auditFlowId) {
          (this.auditButtons as any).fetchCanAudit();
        }
      });
  }

  changeAudit() {
    this.fetchDetail();
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
