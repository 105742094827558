
import {
  Component,
  Vue,
  Inject,
  Prop,
  Watch,
  Ref
} from "vue-property-decorator";

import api from "@/api";
import {
  CmsFriendLinkDto,
  CmsFriendLinkCreateOrUpdateDto, AttachmentHostType
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";

@Component({
  name: "EditFriendLink",
  components: { SimpleUploadImage },
  filters: {
    formatNotifyType(type: string, list: any[]) {
      let NotifyTypeName = ''
      if (list) {
        list.map((res: any) => {
          if (res.value === type) {
            NotifyTypeName = res.text;
            return
          }
        })
      }
      return NotifyTypeName
    }
  }
})
export default class EditFriendLink extends Vue {
  @Ref() readonly dataForm!: ElForm;

  @Prop({ required: true })
  dataTable!: any;

  defaultData: CmsFriendLinkCreateOrUpdateDto = {
    name: undefined,
    link: undefined,
    logo:undefined,
    displayOrder: 0,
    id: 0
  };

  show = false;
  form: CmsFriendLinkCreateOrUpdateDto = { ...this.defaultData };
  NotifyTypeList = [];
  projectName:any="";
  orgName:any="";

  get hostType() {
    return AttachmentHostType.FriendlyLinkLogo;
  }

  get title() {
    return "通知预览";
  }

  @Watch("show")
  async onShowChange(value: boolean) {
    if (value) {
      const loading = this.$loading({
        target: ".el-dialog"
      });
      this.fetchNotifyTypeList();
      // debugger
      if(this!.dataTable!.hostId){
        api.notifyRecord.getNotifyProjectAndUnitInformation({projectId: this!.dataTable!.hostId})
          .then(res => {
            this.projectName = res.projectName;
            this.orgName=res.orgName;
          });
      }

      // if (this.dataId) {
      //   await api.cmsFriendLink
      //     .get({ id: this.dataId })
      //     .then((res: CmsFriendLinkDto) => {
      //       this.form = res!;
      //     })
      //     .finally(() => {
      //       setTimeout(() => {
      //         loading.close();
      //       }, 200);
      //     });
      // } else {
      //   this.form = { ...this.defaultData };
      //   //
      //   setTimeout(() => {
      //     loading.close();
      //   }, 200);
      // }
    } else {
      this.form = { ...this.defaultData };
    }
    // this.$nextTick(() => {
    //   this.dataForm.clearValidate();
    // });
  }

  // 获取通知类型
  fetchNotifyTypeList() {
    api.enumService.getValues({typeName: 'NotifyTemplateType'}).then((res: any) => {
      this.NotifyTypeList = res
    })
  }

  async save() {
    console.log(this.form);
    this.show = false;
    this.$emit("onSave");
  }

  cancel() {
    this.show = false;
  }

  linkRule = {
    name: [
      {
        required: true,
        message: "请输入合作机构",
        trigger: "blur"
      }
    ],
    // link: [
    //   {
    //     required: true,
    //     message: "请输入跳转链接",
    //     trigger: "blur"
    //   }
    // ],
    displayOrder: [
      {
        required: true,
        message: "请输入显示顺序",
        trigger: "change"
      },
      {
        type: "number",
        message: "显示顺序必须为数字",
        trigger: "change"
      }
    ]
  };
}
