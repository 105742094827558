
  import {
    Component, Model, Prop, Ref,
    Vue, Watch,
  } from "vue-property-decorator";
  import ProjectSelect from "../ProjectSelect/index.vue";
  import {ElForm} from "element-ui/types/form";
  import {futimes} from "fs";
  interface selectProjectId {
    projectId: number | string
  }

  @Component({
    name: "SendNotify",
    components: {ProjectSelect}
  })
  export default class SendNotify extends Vue {
    @Ref() readonly dataForm!: ElForm;

    // @Model('change',{required:false,default:''}) readonly value!: number|string;

    @Prop({ required: false, default:true }) isInternal!: boolean;

    @Prop({ required: false, default:'发送填写通知' }) title!: '';

    @Prop({required:true})
    notifyApiUrl!: Function;

    form:selectProjectId = {
      projectId: '',
    }

    // @Watch("value",{deep:true})
    // valueChange(newVal: string|number, oldVal: string|number) {
    //   this.form.projectId = newVal;
    // }

    isShowNotify:boolean = false;

    // changeProject(e: number) {
    //   this.form.projectId = e
    //   this.$emit('change', e)
    // }

    async save() {
      (this.$refs.dataForm as any).validate(async (valid: boolean) => {
        console.log(this.notifyApiUrl,'123123')
        if (valid) {
         await this.notifyApiUrl({projectId:this.form.projectId})

          this.isShowNotify = false;
          this.$message.success("发送成功");
        }
      });

    }

    linkRule = {
      projectId: [
        {
          required: true,
          message: "请选择项目",
          trigger: "blur"
        }
      ]
    };
  }
