
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import api from '@/api/index';
import {ProjectBasicDetailUpdateDto} from "@/api/appService";

@Component({
  name: 'ProjectSubmitDateChangeEdit',
  components: {}
})
export default class ProjectSubmitDateChangeEdit extends Vue {

  @Prop({required: true, default: 0})
  projectId!: number;

  show: boolean = false;


  projectDto: ProjectBasicDetailUpdateDto = {};

  submitting = false;


  @Watch('show')
  onShowChange(value: boolean) {
    if (!value) {
      return;
    }
    api.project.get({id: this.projectId}).then(res => {
      this.projectDto = {...res};
    })
  }


  handleSave() {
    this.submitting = true;
    api.project.updateProjectBasicDetail({body: this.projectDto}).then(res => {
      this.$message.success('更新成功');
      this.show = false;
      this.submitting = false;
    }).catch(res => {
      this.submitting = false;
    });
  }


}
