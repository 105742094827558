

import {Component, Ref, Vue} from "vue-property-decorator";
import {
  AttachmentHostType,
  ExternalProjectIntentionCreateOrUpdateDto, FormType,
} from "@/api/appService";
import api from "@/api";
import {ElForm} from "element-ui/types/form";
import MultipleUploadFile from "@/components/MultipleUploadFile/index.vue";
import moment from "moment";
import TemporarySaving from "@/components/TemporarySaving/index.vue";
import TemporarySavingAlert from "@/components/TemporarySavingAlert/index.vue";

@Component({
  name: "externalProjectLetterIntentEdit",
  components: {
    MultipleUploadFile,
    TemporarySaving,
    TemporarySavingAlert
  },
})
export default class externalProjectLetterIntentEdit extends Vue {
  @Ref() readonly dataForm!: ElForm;
  @Ref() readonly tempSave!: TemporarySaving;
  dataId!: number;
  defaultData: ExternalProjectIntentionCreateOrUpdateDto = {
    displayName: undefined,
    startDate: undefined,
    endDate: undefined,
    place: undefined,
    benefitObject: undefined,
    summary: undefined,
    solvedProblem: undefined,
    activityPlan: undefined,
    budgetPlan: undefined,
    executeExperience: undefined,
    savedAttachments: []
  };

  get hostTypeFile() {
    return AttachmentHostType.External_Project_Intention;
  }

  get formType() {
    return FormType.LetterOfIntent;
  }

  form: ExternalProjectIntentionCreateOrUpdateDto = {...this.defaultData};

  created() {
    this.dataId = Number(this.$route.params.id);
    if (this.dataId) {
      this.fetchDetail()
    }
  }

  // 获取详情
  async fetchDetail() {
    await api.externalProjectIntention.getDetail({id: this.dataId}).then(res => {
      this.form = {...res}
    })
  }

  cancel() {
    this.$router.go(-1)
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.dataId) {
          await api.externalProjectIntention.update({
            body: {...this.form}
          });
        }
        // else {
        //   await api.ExternalProjectIntention.create({
        //     body: { ...this.form }
        //   });
        // }
        this.$message.success("更新成功");

        (this.$refs.tempSave as any).deleteCaChe();

        this.$router.go(-1)
      } else {
        this.$message({
          type: 'error',
          message: '请检查表单'
        })
      }
    });
  }

  setFormData(formStr: string) {
    this.form = JSON.parse(formStr);
  }

  roleRule = {
    displayName: [
      {
        required: true,
        message: "请输入项目名称",
        trigger: "blur"
      }
    ],
    startDate: [
      {
        required: true,
        message: "请选择开始日期",
        trigger: "blur"
      }
    ],
    endDate: [
      {
        required: true,
        message: "请选择结束日期",
        trigger: "blur"
      }
    ],
    place: [
      {
        required: true,
        message: "请输入拟落地的区域",
        trigger: "blur"
      }
    ],
    benefitObject: [
      {
        required: true,
        message: "请输入受益人群",
        trigger: "blur"
      }
    ],
    summary: [
      {
        required: true,
        message: "请输入拟申请项目概述",
        trigger: "blur"
      }
    ],
    solvedProblem: [
      {
        required: true,
        message: "请输入拟解决的问题与拟达到的目标",
        trigger: "blur"
      }
    ],
    activityPlan: [
      {
        required: true,
        message: "请输入拟采取的策略/开展的活动",
        trigger: "blur"
      }
    ],
    budgetPlan: [
      {
        required: true,
        message: "请输入项目初步预算",
        trigger: "blur"
      }
    ]
  }
}
