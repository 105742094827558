
  import {Component, Prop, Vue, Watch} from "vue-property-decorator";
  import api from '@/api/index';
  import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";
  import {
    ExternalProjectBudgetItemDto,
    ExternalProjectCreateOrUpdateDto,
    ExternalProjectDetailDto,
    ExternalProjectSpecificPlanDto,
  } from "@/api/appService";
  import BudgetItems from "@/components/BudgetItems/index.vue";
  import {v4 as uuidv4} from "uuid";

  @Component({
    name:'BudgetItemsEdit',
    components: {
      SimpleUploadImage,
      BudgetItems
    }
  })
  export default class BudgetItemsEdit extends Vue{

    @Prop({required:true,default:0})
    projectId!:number;

    show:boolean = false;

    projectDto:ExternalProjectDetailDto = {};

    submitting = false;

    // 具体计划
    specificPlans: ExternalProjectSpecificPlanDto[] = [
      {
        projectId: undefined,
        projectSpecificGoalId: undefined,
        title: undefined,
        startDate: undefined,
        endDate: undefined,
        activityCount: undefined,
        peopleCount: undefined,
        output: undefined,
        id: this.newGuid()
      }
    ]

    // 项目预算明细
    budgetItems: ExternalProjectBudgetItemDto[] = [
      {
        projectBudgetCategory: undefined,
        projectBudgetType: undefined,
        itemName: undefined,
        projectSpecificPlanId: undefined,
        projectSpecificGoalId: undefined,
        content: undefined,
        unitPrice: undefined,
        number: undefined,
        unit: undefined,
        budgetQuotation: undefined,
        projectBudgetCapitalSource: undefined
      }
    ];
    budgetForm:ExternalProjectCreateOrUpdateDto= {
      budgetItems: this.budgetItems,
      applyMoney: 0,
      selfMoney: 0,
      otherMoney: 0,
      totalMoney: 0,
    }
    form: ExternalProjectCreateOrUpdateDto = {};

    newGuid(){
      return uuidv4();
    }

    @Watch('show')
    onShowChange(value:boolean){
      if(!value){
        return;
      }

      api.externalProject.getDetailByProjectId({projectId:this.projectId}).then(res => {
        this.form = {...res};
        if(!this.form!.specificPlans || this.form!.specificPlans.length === 0) {
          this.form!.specificPlans = this.specificPlans
        }
        if(res.budgetItems && res.budgetItems.length> 0) {
          this.budgetForm.budgetItems = res.budgetItems
        } else {
          this.budgetItems[0].projectId = this.projectId;
          this.budgetForm.budgetItems = this.budgetItems;
        }
        this.budgetForm.applyMoney = res.applyMoney
        this.budgetForm.selfMoney = res.selfMoney
        this.budgetForm.otherMoney = res.otherMoney
        this.budgetForm.totalMoney = res.totalMoney
      });
    }

    handleSave(){
      this.submitting = true;
      const body = {
        budgetItems: this.budgetForm.budgetItems,
        projectId: this.projectId,
        id: this.form.id
      };
      api.externalProject.updateBudgetItem({body:body}).then(res=>{
        this.$message.success('更新成功');
        this.show = false;
        this.submitting = false;
      }).catch(res=>{
        this.submitting = false;
      });
    }

    changeBudgetForm(e:any) {
      Object.assign(this.form,this.form,e)
    }
  }
