
import {Component, Ref, Vue, Watch} from "vue-property-decorator";
import api from "@/api";
import {AttachmentHostType, DataDictionaryDto, ProjectMemberItemDto, FormType} from "@/api/appService";
import {ElForm} from "element-ui/types/form";
import AbSelect from "@/components/AbSelect/index.vue";
import ProjectSelect from "@/components/ProjectSelect/index.vue";
import MultipleUploadFile from "@/components/MultipleUploadFile/index.vue";
import {UserModule} from "@/store/modules/user";
import validate from "@/utils/validate";
import moment from "moment";
import AbCard from "@/components/AbCard/index.vue";
import TemporarySaving from "@/components/TemporarySaving/index.vue";
import TemporarySavingAlert from "@/components/TemporarySavingAlert/index.vue";

@Component({
  components: {
    AbSelect,
    ProjectSelect,
    MultipleUploadFile,
    AbCard,
    TemporarySaving,
    TemporarySavingAlert
  },
  filters: {
    formatBudgetCategory(budgetCategory: string, projectBudgetCategoryList: any[]) {
      let budget: string | undefined
      if (projectBudgetCategoryList && projectBudgetCategoryList.length > 0) {
        projectBudgetCategoryList.map((item: any) => {
          if (item.value == budgetCategory) {
            budget = item.text
            return
          }
        })
      }
      return budget
    },
  }
})
export default class CreateDueDiligenceReord extends Vue {
  @Ref() readonly dataForm!: ElForm;
  @Ref() readonly tempSave!: TemporarySaving;

  sexList: DataDictionaryDto[] = [];
  educationTypeList: DataDictionaryDto[] = [];

  get hostTypeFile() {
    return AttachmentHostType.External_Project_Progress_Report;
  }

  get formType() {
    return FormType.InterimSummaryReport;
  }

  // 项目预算种类 一级
  projectBudgetCategoryList = [];

  // 项目预算种类 二级
  projectBudgetTypeList = [];

  // 资金来源
  projectBudgetCapitalSourceList = [];
  afterProjectBudgetCategoryList: any[] = [
    {
      projectBudgetCategory: 0,
      data: []
    }
  ]
  afterProjectBudgetTypeList: any[] = [
    {
      projectBudgetType: 0,
      data: []
    }
  ]
  afterItemNameList: any[] = [
    {
      itemName: 0,
      data: []
    }
  ]

  projectMembers: ProjectMemberItemDto[] = [
    {
      name: undefined,
      sex: undefined,
      age: undefined,
      educationTypeId: undefined,
      major: undefined,
      qualifications: undefined,
      projectRole: undefined,
      inputPercent: undefined
    }
  ];

  projectSpecificGoals: any = [
    {
      effect: undefined,
      norm: undefined,
      validateCondition: undefined,
      status: undefined

    }
  ];

  budgetUsed: any = [
    {
      subject: undefined,
      planAmount: undefined,
      actualAmount: undefined,
      remark: undefined
    }
  ];

  problems: any = [
    {
      description: undefined,
      reason: undefined,
      solution: undefined
    }
  ]

  form: any = {
    projectId: undefined,
    reportDate: undefined,
    projectMemberJson: undefined,
    projectSpecificGoalJson: undefined,
    interventions: undefined,
    implementStatus: undefined,
    implementEffect: undefined,
    publicityStatus: undefined,
    selfInfluence: undefined,
    innovation: undefined,
    budgetUsedJson: undefined,
    problemsJson: undefined,
    nextPlan: undefined,
    savedAttachments: [],
    id: 0,
  }

  externalProject: any = {
    projectPeriod: undefined,
    objectiveOfMain: undefined,
    serviceGroup: undefined
  }

  budgetItems: any = {}

  progressReportId?: number;
  organizationName: any = UserModule.ou!.displayName
  disabledProject: Boolean = false

  formStr: string = ''

  @Watch("projectMembers", {immediate: true, deep: true})
  setFormProjectMembers(newValue: any) {
    if (newValue) {
      this.form.projectMemberJson = JSON.stringify(newValue);
    }
  }

  @Watch("projectSpecificGoals", {immediate: true, deep: true})
  setFormProjectSpecificGoals(newValue: any) {
    if (newValue) {
      this.form.projectSpecificGoalJson = JSON.stringify(newValue);
    }
  }

  @Watch("budgetUsed", {immediate: true, deep: true})
  setFormBudgetUsed(newValue: any) {
    if (newValue) {
      this.form.budgetUsedJson = JSON.stringify(newValue);
    }
  }

  @Watch("problems", {immediate: true, deep: true})
  setFormProblems(newValue: any) {
    if (newValue) {
      this.form.problemsJson = JSON.stringify(newValue);
    }
  }

  fetchDataDictionary() {
    api.dataDictionary.getDataDictionaryListByKey({key: 'EducationType', maxResultCount: 1000})
      .then((res: any) => {
        this.educationTypeList = res.items
      })
    api.dataDictionary.getDataDictionaryListByKey({key: 'Sex', maxResultCount: 1000})
      .then((res: any) => {
        this.sexList = res.items
      })
  }

  created() {
    console.log('progress reports -- create begin -- form: ' + JSON.stringify(this.form))
    this.fetchEnum();
    this.fetchDataDictionary();
    if (this.$route.query.projectId) {
      this.form.projectId = Number(this.$route.query.projectId);
      this.disabledProject = true;
    }

    if (this.$route.params.id) {
      this.progressReportId = Number(this.$route.params.id);
      this.form.id = this.progressReportId;
      api.externalProjectProcessReport.getDetail({id: this.progressReportId}).then(res => {
        this.form = {...res};
        this.projectMembers = JSON.parse(this.form.projectMemberJson!);
        this.projectSpecificGoals = JSON.parse(this.form.projectSpecificGoalJson!);
        this.budgetUsed = JSON.parse(this.form.budgetUsedJson!);
        this.problems = JSON.parse(this.form.problemsJson!)
        this.organizationName = res.organizationUnit!.displayName;

        api.externalProject.getDetailByProjectId({projectId: this.form.projectId})
          .then((res: any) => {

            this.organizationName = res.organizationUnit.displayName
            this.externalProject.projectPeriod = moment(String(res.startDate)).format('YYYY-MM-DD') + " - " + moment(String(res.endDate)).format('YYYY-MM-DD');
            this.externalProject.objectiveOfMain = res.objectiveOfMain;
            this.externalProject.serviceGroup = res.benefitObjectOfDirect! + "," + res.benefitObjectOfIndirect!
            // 项目预算
            this.budgetItems = res.budgetItems;
            if (res.budgetItems && res.budgetItems.length > 0) {
              let afterProjectBudgetCategory: any[] = [] //预算科目

              let afterProjectBudgetType: any[] = []  //预算类型
              let afterItemName: any[] = []   //子项目（活动）内容

              res.budgetItems!.forEach((item: { projectBudgetCategory: any; projectBudgetType: any; itemName: any; }) => {

                let beforeProjectBudgetCategory = afterProjectBudgetCategory.find(item1 => item1.projectBudgetCategory === item.projectBudgetCategory)
                let beforeProjectBudgetType = afterProjectBudgetType.find(item1 => item1.projectBudgetType === item.projectBudgetType)
                let beforeItemName = afterItemName.find(item1 => item1.itemName === item.itemName)

                if (!beforeItemName) {
                  afterItemName.push({
                    itemName: item.itemName,
                    data: [item]
                  })
                } else {
                  beforeItemName.data.push(item)
                }

                if (!beforeProjectBudgetType) {
                  afterProjectBudgetType.push({
                    projectBudgetType: item.projectBudgetType,
                    data: [item]
                  })
                } else {
                  beforeProjectBudgetType.data.push(item)
                }
                //
                //
                if (!beforeProjectBudgetCategory) {
                  afterProjectBudgetCategory.push({
                    projectBudgetCategory: item.projectBudgetCategory,
                    data: [item]
                  })
                } else {
                  beforeProjectBudgetCategory.data.push(item)
                }

              })
              this.afterProjectBudgetCategoryList = afterProjectBudgetCategory
              this.afterProjectBudgetTypeList = afterProjectBudgetType
              this.afterItemNameList = afterItemName
              console.log(this.afterItemNameList)
            }
          });

      });


    }
    console.log('progress reports -- create finish -- form: ' + JSON.stringify(this.form))
  }

  get defaultAutoSize() {
    return {
      minRows: 2
    }
  }

  selectProject(e: number) {
    this.clearData(e);
    api.externalProject.getDetailByProjectId({projectId: e})
      .then((res: any) => {
        this.organizationName = res.organizationUnit.displayName
        this.externalProject.projectPeriod = moment(String(res.startDate)).format('YYYY-MM-DD') + " - " + moment(String(res.endDate)).format('YYYY-MM-DD');
        this.externalProject.objectiveOfMain = res.objectiveOfMain;
        this.externalProject.serviceGroup = res.benefitObjectOfDirect! + "," + res.benefitObjectOfIndirect!
        this.projectSpecificGoals = res.effects;
        // 项目预算
        this.budgetItems = res.budgetItems;
        if (res.budgetItems && res.budgetItems.length > 0) {
          let afterProjectBudgetCategory: any[] = [] //预算科目

          let afterProjectBudgetType: any[] = []  //预算类型
          let afterItemName: any[] = []   //子项目（活动）内容

          res.budgetItems!.forEach((item: { projectBudgetCategory: any; projectBudgetType: any; itemName: any; }) => {

            let beforeProjectBudgetCategory = afterProjectBudgetCategory.find(item1 => item1.projectBudgetCategory === item.projectBudgetCategory)
            let beforeProjectBudgetType = afterProjectBudgetType.find(item1 => item1.projectBudgetType === item.projectBudgetType)
            let beforeItemName = afterItemName.find(item1 => item1.itemName === item.itemName)

            if (!beforeItemName) {
              afterItemName.push({
                itemName: item.itemName,
                data: [item]
              })
            } else {
              beforeItemName.data.push(item)
            }

            if (!beforeProjectBudgetType) {
              afterProjectBudgetType.push({
                projectBudgetType: item.projectBudgetType,
                data: [item]
              })
            } else {
              beforeProjectBudgetType.data.push(item)
            }
            //
            //
            if (!beforeProjectBudgetCategory) {
              afterProjectBudgetCategory.push({
                projectBudgetCategory: item.projectBudgetCategory,
                data: [item]
              })
            } else {
              beforeProjectBudgetCategory.data.push(item)
            }

          })
          this.afterProjectBudgetCategoryList = afterProjectBudgetCategory
          this.afterProjectBudgetTypeList = afterProjectBudgetType
          this.afterItemNameList = afterItemName
          console.log(this.afterItemNameList)
        }

        this.setFormDataOnceMore();
      });

  }

  clearData(projectId: number) {
    let oldFiles = this.form.savedAttachments
    this.form = {
      id: this.progressReportId ? this.progressReportId : 0,
      projectId: projectId,
      reportDate: undefined,
      projectMemberJson: undefined,
      projectSpecificGoalJson: undefined,
      interventions: undefined,
      implementStatus: undefined,
      implementEffect: undefined,
      publicityStatus: undefined,
      selfInfluence: undefined,
      innovation: undefined,
      budgetUsedJson: undefined,
      problemsJson: undefined,
      nextPlan: undefined,
    };
    this.form.savedAttachments = oldFiles

    this.projectMembers = [
      {
        name: undefined,
        sex: undefined,
        age: undefined,
        educationTypeId: undefined,
        major: undefined,
        qualifications: undefined,
        projectRole: undefined,
        inputPercent: undefined
      }
    ]

    this.externalProject = {
      projectPeriod: undefined,
      objectiveOfMain: undefined,
      serviceGroup: undefined
    }

    this.projectSpecificGoals = [
      {
        effect: undefined,
        norm: undefined,
        validateCondition: undefined,
        status: undefined,
      }
    ]

    this.budgetUsed = [
      {
        subject: undefined,
        planAmount: undefined,
        actualAmount: undefined,
        remark: undefined
      }
    ]

    this.problems = [
      {
        description: undefined,
        reason: undefined,
        solution: undefined
      }
    ]

    this.externalProject = {
      projectPeriod: undefined,
      objectiveOfMain: undefined,
      serviceGroup: undefined
    }
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        // this.form.projectMemberJson = JSON.stringify(this.projectMembers);
        // this.form.projectSpecificGoalJson = JSON.stringify(this.projectSpecificGoals);
        // this.form.budgetUsedJson = JSON.stringify(this.budgetUsed);
        // this.form.problemsJson = JSON.stringify(this.problems)

        if (this.form!.id) {
          await api.externalProjectProcessReport.update({
            body: this.form
          }).then(() => {
            this.$message.success("更新成功");
            (this.$refs.tempSave as any).deleteCaChe();
            this.$router.back();
          });
        } else {
          await api.externalProjectProcessReport.create({
            body: this.form
          }).then(() => {
            this.$message.success("新建成功");
            (this.$refs.tempSave as any).deleteCaChe();
            this.$router.back();
          });
        }
      }
    });
  }

  setFormData(formStr: string) {
    this.formStr = formStr;

    this.form = JSON.parse(this.formStr);
    console.log('progress reports -- form:' + JSON.stringify(this.form))

    this.projectMembers = JSON.parse(this.form.projectMemberJson);
    this.projectSpecificGoals = JSON.parse(this.form.projectSpecificGoalJson);
    this.budgetUsed = JSON.parse(this.form.budgetUsedJson);
    this.problems = JSON.parse(this.form.problemsJson);
  }

  setFormDataOnceMore() {
    if (this.formStr && this.formStr.length > 0) {
      this.form = JSON.parse(this.formStr);
      console.log('progress reports -- form:' + JSON.stringify(this.form))

      this.projectMembers = JSON.parse(this.form.projectMemberJson);
      this.projectSpecificGoals = JSON.parse(this.form.projectSpecificGoalJson);
      this.budgetUsed = JSON.parse(this.form.budgetUsedJson);
      this.problems = JSON.parse(this.form.problemsJson);

      this.formStr = '';
    }
  }

  cancel() {
    this.$router.back();
  }

  fetchEnum() {
    api.enumService.getValues({typeName: 'ProjectBudgetCategory'}).then((res: any) => {
      this.projectBudgetCategoryList = res
    });
    api.enumService.getValues({typeName: 'ProjectBudgetType'}).then((res: any) => {
      this.projectBudgetTypeList = res
    });
    api.enumService.getValues({typeName: 'ProjectBudgetCapitalSource'}).then((res: any) => {
      this.$nextTick(() => {
        this.projectBudgetCapitalSourceList = res
      })
    });
  }

  //增加项目执行团队及人员分工
  private addRow(r: number) {
    let obj = {
      name: undefined,
      sex: undefined,
      age: undefined,
      educationTypeId: undefined,
      major: undefined,
      qualifications: undefined,
      projectRole: undefined,
      inputPercent: undefined
    };

    this.projectMembers = [...this.projectMembers!, obj];
  }

  // 删除项目执行团队及人员分工
  private deleteRow(index: number) {
    if (this.projectMembers!.length === 1) {
      return;
    }
    this.projectMembers!.splice(index, 1);
  }

  //增加指标完成情况
  private addPojectSpecificGoalsRow(r: number) {
    var obj = {
      goals: undefined,
      goalsSummary: undefined,
      condition: undefined

    };

    this.projectSpecificGoals = [...this.projectSpecificGoals!, obj];
  }

  // 删除指标完成情况
  private deleteProjectSpecificGoalsRow(index: number) {
    if (this.projectSpecificGoals!.length === 1) {
      return;
    }
    this.projectSpecificGoals!.splice(index, 1);
  }

  //增加项目经费使用情况
  private addBudgetUserdRow(r: number) {
    var obj = {
      subject: undefined,
      planAmount: undefined,
      actualAmount: undefined,
      remark: undefined
    };

    this.budgetUsed = [...this.budgetUsed!, obj];
  }

  // 删除项目经费使用情况
  private deleteBudgetUserdRow(index: number) {
    if (this.budgetUsed!.length === 1) {
      return;
    }
    this.budgetUsed!.splice(index, 1);
  }

  //增加项目执行中的问题和建议
  private addProblemRow(r: number) {
    var obj = {
      description: undefined,
      reason: undefined,
      solution: undefined,
    };

    this.problems = [...this.problems!, obj];
  }


  // 删除项目执行中的问题和建议
  private deleteProblemRow(index: number) {
    if (this.problems!.length === 1) {
      return;
    }
    this.problems!.splice(index, 1);
  }


  progressReprotRule = {
    projectId: [
      {
        required: true,
        message: "项目名称必填",
        trigger: "blur"
      }
    ],
    reportDate: [
      {
        required: true,
        message: "提交时间必填",
        trigger: "blur"
      }
    ],
    interventions: [
      {
        required: true,
        message: "介入方式必填",
        trigger: "blur"
      }
    ],
    implementStatus: [
      {
        required: true,
        message: "项目开展情况必填",
        trigger: "blur"
      }
    ],
    implementEffect: [
      {
        required: true,
        message: "项目实施成果必填",
        trigger: "blur"
      }
    ],
    publicityStatus: [
      {
        required: true,
        message: "项目宣传情况必填",
        trigger: "blur"
      }
    ],
    selfInfluence: [
      {
        required: true,
        message: "项目实施对组织影响必填",
        trigger: "blur"
      }
    ],
    innovation: [
      {
        required: true,
        message: "项目创新性及可推广性必填",
        trigger: "blur"
      }
    ],
    nextPlan: [
      {
        required: true,
        message: "下半年计划必填",
        trigger: "blur"
      }
    ],
    name: [
      {
        required: true,
        message: "姓名必填",
        trigger: "blur"
      }
    ],
    age: [
      {
        required: true,
        trigger: "blur",
        validator: validate.validAge
      }
    ],
    sex: [
      {
        required: true,
        trigger: "blur",
        message: "性别必填",
      }
    ],
    educationTypeId: [
      {
        required: true,
        message: "学历必填",
        trigger: "blur"
      }
    ],
    major: [
      {
        required: true,
        message: "学历必填",
        trigger: "blur"
      }
    ],
    qualifications: [
      {
        required: true,
        message: "资质必填",
        trigger: "blur"
      }
    ],
    projectRole: [
      {
        required: true,
        message: "团队分工必填",
        trigger: "blur"
      }
    ],
    inputPercent: [
      {
        required: true,
        message: "备注必填",
        trigger: "blur"
      }
    ],
    effect: [
      {
        required: true,
        message: "项目成效必填",
        trigger: "blur"
      }
    ],
    norm: [
      {
        required: true,
        message: "具体指标必填",
        trigger: "blur"
      }
    ],
    validateCondition: [
      {
        required: true,
        message: "验证条件必填",
        trigger: "blur"
      }
    ],
    status: [
      {
        required: true,
        message: "完成情況必填",
        trigger: "blur"
      }
    ],
    subject: [
      {
        required: true,
        message: "科目/版块必填",
        trigger: "blur"
      }
    ],
    planAmount: [
      {
        required: true,
        trigger: "blur",
        validator: validate.validMoney
      }
    ],
    actualAmount: [
      {
        required: true,
        trigger: "blur",
        validator: validate.validMoney

      }
    ],
    /*   remark: [
           {
               required: true,
               message: "提交时间必填",
               trigger: "blur"
           }
       ],*/
    description: [
      {
        required: true,
        message: "问题描述必填",
        trigger: "blur"
      }
    ],
    reason: [
      {
        required: true,
        message: "原因分析必填",
        trigger: "blur"
      }
    ],
    solution: [
      {
        required: true,
        message: "解决策略必填",
        trigger: "blur"
      }
    ],


  };
}
