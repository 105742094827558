
import {Component, Ref, Vue,} from "vue-property-decorator";
import api from "@/api/index";
import PagedTableView from "@/components/PagedTableView/index.vue";
import moment from "moment";
import {ProgressReportDto, AuditFlowScope, FormType} from "@/api/appService";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
import SendNotify from "@/components/SendNotify/index.vue";

@Component({
  name: "projectProgressReport",
  components: {
    SendNotify,
    PagedTableView,
    AgileAuditTag

  },
})

export default class ProjectProgressReportList extends Vue {
  @Ref() showNotify!: SendNotify;

  tableItems: ProgressReportDto[] = [];
  queryForm: any = {
    startDate: undefined,
    endDate: undefined,
    organizationUnitName: undefined,
    projectName: undefined
  };
  notifyProjectId: number | string = '';
  formType: FormType = FormType.InterimSummaryReport;

  get getNotifyApiUrl() {
    return api.externalProjectProcessReport.sendNotify
  }

  created() {
    if (this.$route.query.projectId) {
      this.queryForm.projectId = Number(this.$route.query.projectId);
    }
  }

  fetchData(params: any) {
    return api.externalProjectProcessReport.getAll(params);
  }

  handleMenu($row: any) {
    switch ($row.type) {
      case "detail":
        this.handleDetail($row.item);
        break;

      case "edit":
        this.handleEdit($row.index, $row.item);
        break;

      case "delete":
        this.handleDelete($row.index, $row.item);
        break;

      case 'startAudit':
        this.handleStartAudit($row.index, $row.item)
        break;

      case 'auditFlow':
        this.handleAuditFlow($row.index, $row.item)
        break;

      case 'notice':
        this.handleSendNotify($row.index, $row.item)
        break;

    }
  }

  //获取审核状态
  // getAuditStatus(index: number, row: any) {
  //   if ((row.audit != null && row.audit >= 0) && row.auditStatus === row.audit) {//已审核
  //     return true;
  //   } else if (row.audit === -1) {//被拒绝
  //     return false;
  //   } else if (row.audit == null) {//未开始
  //     return false;
  //   } else {//审核中
  //     return true;
  //   }
  // }

  // 发送通知
  handleShowNotify() {
    (this.showNotify as any).isShowNotify = true
  }

  handleCreate() {
    this.$router.push({name: "externalProject-progressReports-create"});
  }

  handleEdit(index: number, row: any) {
    this.$router.push({
      name: "externalProject-progressReports-edit",
      params: {id: row.id!.toString()}
    });
  }

  handleDetail(row: ProgressReportDto) {
    this.$router.push({
      name: "externalProject-progressReports-detail",
      params: {id: row.id!.toString()}
    });
  }

  //  提交审核
  async handleStartAudit(index: number, row: ProgressReportDto) {
    await api.externalProjectProcessReport.startAudit({body: {id: row.id}}).then(() => {
      api.formCache.delCache({formType: this.formType, hostId: row.id?.toString()}).then(() => {
        this.$message.success("已提交");
      })
    });
  }


  // 删除
  async handleDelete(index: number, row: ProgressReportDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.externalProjectProcessReport.delete({id: row.id}).then(() => {
        this.$message({
          type: "success",
          message: "删除成功!"
        });
      });
    });
  }

  handleAuditFlow(index: number, row: any) {
    this.$router.push({
        name: 'auditFlowDetail', params: {
          hostType: AuditFlowScope.ExternalProjectProgressReport,
          hostId: row.id,
          auditFlowId: row.auditFlowId
        }
      }
    );
  }

  handleSendNotify(index: number, row: ProgressReportDto) {
    this.$confirm("你确定发送通知吗?", "提示").then(async () => {
      api.externalProjectProcessReport.sendNotify({projectId: row.id}).then(() => {
        this.$message({
          type: "success",
          message: "发送成功!"
        });
      });
    })
  }
}
