
import {Component, Ref, Vue} from "vue-property-decorator";
import api from "@/api";
import {
  AttachmentHostType,
  DataDictionaryDto,
  ExternalProjectBudgetItemDto,
  ExternalProjectCreateOrUpdateDto,
  ExternalProjectSpecificPlanDto,
  FormType,
  ProjectMemberItemDto,
  ProjectRiskPlanDto,
  ProjectSpecificGoalDto
} from "@/api/appService";
import {ElForm} from "element-ui/types/form";
import AbSelect from "@/components/AbSelect/index.vue";
import ProjectSelect from "@/components/ProjectSelect/index.vue";
import MultipleUploadFile from "@/components/MultipleUploadFile/index.vue";
import BudgetItems from "@/components/BudgetItems/index.vue";
import AbCard from "@/components/AbCard/index.vue";
import {v4 as uuidv4} from "uuid";
import TemporarySaving from "@/components/TemporarySaving/index.vue";
import TemporarySavingAlert from "@/components/TemporarySavingAlert/index.vue";

@Component({
  components: {
    AbSelect,
    ProjectSelect,
    MultipleUploadFile,
    BudgetItems,
    AbCard,
    TemporarySaving,
    TemporarySavingAlert
  }
})
export default class CreateDueDiligenceReord extends Vue {
  @Ref() readonly dataForm!: ElForm;
  @Ref() readonly tempSave!: TemporarySaving;
  projectType = "ExternalProject";
  sourceProjectId: number = 0;
  targetExternalProjectId: number = 0;
  educationTypeList: DataDictionaryDto[] = [];
  sexList: DataDictionaryDto[] = [];
  // 具体计划
  specificPlans: ExternalProjectSpecificPlanDto[] = [
    {
      // projectId: undefined,
      projectSpecificGoalId: undefined,
      title: undefined,
      startDate: undefined,
      endDate: undefined,
      activityCount: undefined,
      peopleCount: undefined,
      output: undefined,
      id: this.newGuid()
    }
  ];
  // 项目成效及成功指标
  effects: ProjectSpecificGoalDto[] = [
    {
      effect: '请输入项目成效',
      norm: undefined,
      validateCondition: undefined,
      id: this.newGuid()
    }
  ];
  // 项目风险预案
  riskPlans: ProjectRiskPlanDto[] = [
    {
      risk: undefined,
      copingStrategy: undefined
    }
  ];
  // 项目团队信息
  projectMemberItems: ProjectMemberItemDto[] = [
    {
      name: undefined,
      sex: undefined,
      age: undefined,
      educationTypeId: undefined,
      major: undefined,
      qualifications: undefined,
      projectRole: undefined,
      inputPercent: undefined
    }
  ];
  // 项目预算明细
  budgetItems: ExternalProjectBudgetItemDto[] = [
    {
      projectBudgetCategory: undefined,
      projectBudgetType: undefined,
      itemName: undefined,
      projectSpecificPlanId: undefined,
      projectSpecificGoalId: undefined,
      content: undefined,
      unitPrice: undefined,
      number: undefined,
      unit: undefined,
      budgetQuotation: undefined,
      projectBudgetCapitalSource: undefined
    }
  ];
  budgetForm: ExternalProjectCreateOrUpdateDto = {
    budgetItems: this.budgetItems,
    applyMoney: 0,
    selfMoney: 0,
    otherMoney: 0,
    totalMoney: 0
  };
  form: ExternalProjectCreateOrUpdateDto = {
    displayName: undefined,
    summary: undefined,
    problemDescription: undefined,
    areaDescription: undefined,
    otherExperience: undefined,
    place: undefined,
    startDate: undefined,
    endDate: undefined,
    benefitObjectOfDirect: undefined,
    benefitObjectOfIndirect: undefined,
    objectiveOfMain: undefined,

    specificPlans: this.specificPlans,
    effects: this.effects,
    riskPlans: this.riskPlans,
    projectMemberItems: this.projectMemberItems,
    projectMangerInfo: undefined,
    savedAttachments: [],
    id: 0
  };
  formType: FormType = FormType.ProjectPlanningPaper;

  get hostTypeFile() {
    return AttachmentHostType.External_Project;
  }

  get isLoadTemporarySaving() {
    return this.formType === FormType.ProjectPlanningPaper;
  }

  created() {
    this.fetchDataDictionary();
    //项目变更申请扩展
    if (this.$route.params.sourceProjectId) {
      this.formType = FormType.ExternalProjectPlanChangeApplication;
      this.sourceProjectId = Number(this.$route.params.sourceProjectId);
      this.fetchDetail();
    }
    if (this.$route.params.targetExternalProjectId) {
      this.formType = FormType.ExternalProjectPlanChangeApplication;
      this.targetExternalProjectId = Number(
        this.$route.params.targetExternalProjectId
      );
      this.form.id = Number(this.$route.params.targetExternalProjectId);
      this.fetchDetail();
    }
    //扩展结束
    if (this.$route.params.id) {
      this.formType = FormType.ProjectPlanningPaper;
      this.form.id = Number(this.$route.params.id);
      this.fetchDetail();

    }
  }

  fetchDataDictionary() {
    api.dataDictionary
      .getDataDictionaryListByKey({
        key: "EducationType",
        maxResultCount: 1000
      })
      .then((res: any) => {
        this.educationTypeList = res.items;
      });
    api.dataDictionary
      .getDataDictionaryListByKey({key: "Sex", maxResultCount: 1000})
      .then((res: any) => {
        this.sexList = res.items;
      });
  }

  fetchDetail() {
    if (this.sourceProjectId > 0) {
      api.externalProject
        .getDetailBySourceProjectId({projectId: this.sourceProjectId})
        .then(res => {
          this.form = {...res};
          this.form.savedAttachments = res.savedAttachments ?? [];
          if (
            !this.form!.specificPlans ||
            this.form!.specificPlans.length === 0
          ) {
            this.form!.specificPlans = this.specificPlans;
          }
          if (
            !this.form!.specificPlans ||
            this.form!.specificPlans.length === 0
          ) {
            this.form!.specificPlans = this.specificPlans;
          }
          if (!this.form!.effects || this.form!.effects.length === 0) {
            this.form!.effects = this.effects;
          }
          if (!this.form!.riskPlans || this.form!.riskPlans.length === 0) {
            this.form!.riskPlans = this.riskPlans;
          }
          if (
            !this.form!.projectMemberItems ||
            this.form!.projectMemberItems.length === 0
          ) {
            this.form!.projectMemberItems = this.projectMemberItems;
          }
          if (res.budgetItems && res.budgetItems.length > 0) {
            this.budgetForm.budgetItems = res.budgetItems;
          }
          this.budgetForm.applyMoney = res.applyMoney;
          this.budgetForm.selfMoney = res.selfMoney;
          this.budgetForm.otherMoney = res.otherMoney;
          this.budgetForm.totalMoney = res.totalMoney;
        });
    } else {
      api.externalProject.getDetail({id: this.form.id}).then(res => {
        this.form = {...res};
        this.form.savedAttachments = res.savedAttachments ?? [];
        if (
          !this.form!.specificPlans ||
          this.form!.specificPlans.length === 0
        ) {
          this.form!.specificPlans = this.specificPlans;
        }
        if (
          !this.form!.specificPlans ||
          this.form!.specificPlans.length === 0
        ) {
          this.form!.specificPlans = this.specificPlans;
        }
        if (!this.form!.effects || this.form!.effects.length === 0) {
          this.form!.effects = this.effects;
        }
        if (!this.form!.riskPlans || this.form!.riskPlans.length === 0) {
          this.form!.riskPlans = this.riskPlans;
        }
        if (
          !this.form!.projectMemberItems ||
          this.form!.projectMemberItems.length === 0
        ) {
          this.form!.projectMemberItems = this.projectMemberItems;
        }
        if (res.budgetItems && res.budgetItems.length > 0) {
          this.budgetForm.budgetItems = res.budgetItems;
        }
        this.budgetForm.applyMoney = res.applyMoney;
        this.budgetForm.selfMoney = res.selfMoney;
        this.budgetForm.otherMoney = res.otherMoney;
        this.budgetForm.totalMoney = res.totalMoney;
      });
    }
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        // this.form!.specificPlans = this.specificPlans;
        // this.form!.effects = this.effects;
        // this.form!.riskPlans = this.riskPlans;
        // this.form!.projectMemberItems = this.projectMemberItems;

        if (this.sourceProjectId > 0) {
          await api.externalProject
            .copyCreate({
              body: this.form
            })
            .then(res => {
              this.$message.success("计划书更新成功");
              // (this.$refs.tempSave as any).deleteCaChe();
              this.$router.push({
                name: "externalProjectChangeApplyCreate",
                params: {
                  targetId: res.toString(),
                  projectId: this.sourceProjectId.toString()
                }
              });
            });
        } else {
          if (this.form!.id) {
            await api.externalProject.update({
              body: this.form
            }).then(() => {
              this.$router.back();
              this.$message.success("更新成功");
            });
          } else {
            await api.externalProject.create({
              body: this.form
            }).then(() => {
              this.$router.back();
              this.$message.success("新建成功");
            });
          }
          (this.$refs.tempSave as any).deleteCaChe();
        }
      }
    });
  }

  cancel() {
    this.$router.back();
  }

  // 添加项目目标
  private addSpecificPlansRow(index: number) {
    const specificPlan: ExternalProjectSpecificPlanDto = {
      // projectId: undefined,
      id: this.newGuid(),
      projectSpecificGoalId: undefined,
      title: undefined,
      startDate: undefined,
      endDate: undefined,
      activityCount: undefined,
      peopleCount: undefined,
      output: undefined
    };
    this.form!.specificPlans!.splice(index + 1, 0, specificPlan);
    // this.form.specificPlans = [...this.form.specificPlans!, specificPlan];
  }

  // 项目成效及成功指标
  private deleteSpecificPlansRow(index: number) {
    if (this.form.specificPlans!.length === 1) {
      return;
    }
    this.form.specificPlans!.splice(index, 1);
  }

  // 添加项目成效及成功指标
  private addEffectsRow(index: number) {
    const effect: ProjectSpecificGoalDto = {
      effect: undefined,
      norm: undefined,
      validateCondition: undefined,
      id: this.newGuid()
    };
    this.form!.effects!.splice(index + 1, 0, effect);
  }

  // 删除项目成效及成功指标
  private deleteEffectsRow(index: number) {
    if (this.form.effects!.length === 1) {
      return;
    }
    this.form.effects!.splice(index, 1);
  }

  // 添加项目风险预案
  private addRiskPlansRow(index: number) {
    const riskPlan: ProjectRiskPlanDto = {
      risk: undefined,
      copingStrategy: undefined
    };
    this.form!.riskPlans!.splice(index + 1, 0, riskPlan);
    // this.form.riskPlans = [...this.form.riskPlans!, riskPlan];
  }

  // 删除项目风险预案
  private deleteRiskPlansRow(index: number) {
    if (this.form.riskPlans!.length === 1) {
      return;
    }
    this.form.riskPlans!.splice(index, 1);
  }

  // 添加项目团队信息
  private addProjectMemberItemsRow(index: number) {
    const projectMemberItem: ProjectMemberItemDto = {
      name: undefined,
      educationTypeId: undefined,
      major: undefined,
      projectRole: undefined,
      inputPercent: undefined
    };
    this.form!.projectMemberItems!.splice(index + 1, 0, projectMemberItem);
  }

  // 删除项目团队信息
  private deleteProjectMemberItemsRow(index: number) {
    if (this.form.projectMemberItems!.length === 1) {
      return;
    }
    this.form.projectMemberItems!.splice(index, 1);
  }

  changeBudgetForm(e: any) {
    Object.assign(this.form, this.form, e);
  }

  newGuid() {
    return uuidv4();
  }

  setFormData(formStr: string) {
    this.form = JSON.parse(formStr);
    this.budgetForm = {
      budgetItems: this.form.budgetItems,
      applyMoney: this.form.applyMoney,
      selfMoney: this.form.selfMoney,
      otherMoney: this.form.otherMoney,
      totalMoney: this.form.totalMoney
    }
  }

  roleRule = {};
}
