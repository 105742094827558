

  import {Component, Prop, Vue, Watch} from "vue-property-decorator";
  import api from '@/api/index';
  import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";
  import {
    AttachmentHostType,
    ProjectBasicDetailUpdateDto,
    ProjectCreateOrUpdateDto,
    ProjectDto
  } from "@/api/appService";

  @Component({name:'ProjectUploadTitleImage',
    components: {SimpleUploadImage}
  })
  export default class ProjectUploadTitleImage extends Vue{

    @Prop({required:true,default:0})
    projectId!:number;

    show:boolean = false;

    imageUrl ='';

    projectDto:ProjectBasicDetailUpdateDto = {};

    submitting = false;

    created(){

    }

    get hostType(){
      return AttachmentHostType.Internal_Project;
    }


    @Watch('show')
    onShowChange(value:boolean){
      if(!value){
        return;
      }
      api.project.get({id:this.projectId}).then(res=>{
        this.projectDto = {...res};
        this.imageUrl = this.projectDto.titleImagePath??'';
      })
    }


    handleSave(){
      this.submitting = true;
      const body = Object.assign(this.projectDto,{titleImagePath:this.imageUrl});
      api.project.updateProjectBasicDetail({body:body}).then(res=>{
        this.$message.success('更新成功');
        this.show = false;
        this.submitting = false;
      }).catch(res=>{
        this.submitting = false;
      });
    }


  }
