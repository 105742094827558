
import { Component, Ref, Vue } from "vue-property-decorator";
import {
  AttachmentDto,
  AttachmentHostType,
  AuditFlowScope,
  AuditUserLog,
  ExternalProjectIntentionCreateOrUpdateDto
} from "@/api/appService";
import api from "@/api";
import ExportWord from "@/components/DownloadWord/index.vue";
import AttachmentsView from "@/components/AttachmentsView/index.vue";
import AuditButtons from "@/components/AuditButtons/index.vue";
import { AuditBodyDto } from "@/utils/customDto";

@Component({
  name: "externalProjectLetterIntentDetail",
  components: {
    ExportWord,
    AttachmentsView,
    AuditButtons
  }
})
export default class externalProjectLetterIntentDetail extends Vue {
  @Ref() auditButtons!: AuditButtons;
  dataId?: number;
  detail: ExternalProjectIntentionCreateOrUpdateDto = {};
  attachmentList: AttachmentDto[] = [];
  selector: string = "table-detail-world";
  filename: string = "项目意向书";
  styles: string = "";
  hostType = AttachmentHostType.External_Project_Intention;

  auditParams: AuditBodyDto = {
    hostId: undefined,
    hostType: AuditFlowScope.ExternalProjectIntention,
    auditFlowId: undefined,
    auditStatus: undefined,
    audit: undefined
  };

  created() {
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
      this.fetchDetail();
      this.getAttachments();
    }
  }

  fetchData() {
    return api.externalProjectIntention.audit;
  }

  // 获取详情
  async fetchDetail() {
    await api.externalProjectIntention
      .getDetail({ id: this.dataId })
      .then(res => {
        this.detail = { ...res };
        this.auditParams!.hostId = res.id + "";
        this.auditParams!.auditFlowId = res.auditFlowId;
        this.auditParams!.auditStatus = res.auditStatus;
        this.auditParams!.audit = res.audit;
        if (res.auditFlowId) {
          (this.auditButtons as any).fetchCanAudit();
        }
      });
  }

  // 获取附件
  async getAttachments() {
    await api.attachmentService
      .getAttachments({
        hostId: this.dataId!.toString(),
        hostType: AttachmentHostType.External_Project_Intention
      })
      .then(res => {
        this.attachmentList = res;
      });
  }

  changeAudit() {
    this.fetchDetail();
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
