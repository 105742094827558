

import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {FormType} from "@/api/appService";
import api from '@/api';

@Component
export default class TemporarySaving extends Vue {
  @Prop({required: true}) formData!: any;

  @Prop({required: true}) formType!: FormType;

  @Prop({required: false, default: ''}) hostId?: number;

  formStr: string = '';
  type!: FormType;
  id: string = '';

  @Watch("formData", {immediate: true, deep: true})
  getForm(newValue: any) {
    if (newValue) {
      this.formStr = JSON.stringify(newValue);
    }
  }

  @Watch("formType", {immediate: true})
  getType(newValue: FormType) {
    this.type = newValue;
  }

  @Watch("hostId", {immediate: true})
  getId(newValue?: number) {
    if (newValue && newValue > 0) {
      this.id = newValue?.toString();
    }

  }

  mounted() {
    this.$nextTick(() => {
      setInterval(this.handleTemporarySaving, 1000 * 60 * 5); // 5分钟执行一次
    })
  }

  created() {
    console.log('temporarySaving -- created -- formData' + this.formData)
    this.fetchData();
  }

  fetchData() {
    console.log('temporarySaving -- id ' + this.id)
    api.formCache.getCache({formType: this.type, hostId: this.id}).then(res => {
      if (res && res.length > 0) {
        this.$confirm('确定加载临时保存的表单吗?', '提示').then(() => {
          this.$emit('setFormData', res)
        })
      }

    })
  }

  handleTemporarySaving(isAuto: boolean = true) {

    console.log('temporarySaving -- formStr ' + this.formStr)
    console.log('temporarySaving -- type ' + this.type)
    console.log('temporarySaving -- id ' + this.id)
    api.formCache.setCache({body: {data: this.formStr, formType: this.type, hostId: this.id}}).then(res => {
      if (!isAuto) {
        this.$message.success('临时保存成功');
      }
    })
  }

  deleteCaChe() {
    api.formCache.delCache({formType: this.type, hostId: this.id}).then(res => {

    })
  }
}
