
import {Component, Vue,} from "vue-property-decorator";
import api from "@/api/index";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import moment from "moment";
import {AuditFlowScope, ExternalProjectIntentionDto, FormType} from "@/api/appService";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
import AuditFlowDetailButton from "@/components/AuditFlowDetailButton/index.vue";
import {UserModule} from "@/store/modules/user";

@Component({
  name: "externalProjectLetterIntentList",
  components: {
    PagedTableView,
    AbSelect,
    AgileAuditTag,
    AuditFlowDetailButton
  }
})

export default class externalProjectLetterIntentList extends Vue {

  queryForm = {
    displayName: undefined,
  }
  formType: FormType = FormType.LetterOfIntent;

  get canEdit() {
    return UserModule.roles.find(x => x === "FoundationProject") ? true : false;
  }

  fetchData(params: any) {
    return api.externalProjectIntention.getAll(params);
  }

  //获取审核状态
  getAuditStatus(index: number, row: any) {
    if ((row.audit != null && row.audit >= 0) && row.auditStatus === row.audit) {//已审核
      return true;
    } else if (row.audit === -1) {//被拒绝
      return false;
    } else if (row.audit == null) {//未开始
      return false;
    } else {//审核中
      return true;
    }
  }

  // 操作按钮
  handleMenu($row: any) {
    switch ($row.type) {
      case 'detail':
        this.handleDetail($row.item)
        break

      case 'edit':
        this.handleEdit($row.index, $row.item)
        break

      case 'delete':
        this.handleDelete($row.index, $row.item)
        break

      case 'startAudit':
        this.handleStartAudit($row.index, $row.item)
        break

      case 'auditFlow':
        this.handleAuditFlow($row.index, $row.item)
        break


    }
  }

  handleCreate() {
    this.$router.push({
      name: 'monthlyPlan-create'
    })
  }

  handleEdit(index: number, row: any) {
    this.$router.push({
      name: 'externalProjectLetterIntent-edit',
      params: {
        id: row.id
      }
    })
  }

  handleDetail(row: any) {
    this.$router.push({
      name: 'externalProjectLetterIntent-detail',
      params: {
        id: row.id
      }
    })
  }

  handleAuditFlow(index: number, row: any) {
    this.$router.push({
        name: 'auditFlowDetail', params: {
          hostType: AuditFlowScope.ExternalProjectIntention,
          hostId: row.id,
          auditFlowId: row.auditFlowId
        }
      }
    );
  }

  //  提交审核
  async handleStartAudit(index: number, row: ExternalProjectIntentionDto) {
    await api.externalProjectIntention.startAuditWithValidData({id: row.id}).then(() => {
      api.formCache.delCache({formType: this.formType, hostId: row.id?.toString()}).then(() => {
        this.$message.success("已提交");
      })
    });
  }

  // 删除
  async handleDelete(index: number, row: ExternalProjectIntentionDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.externalProjectIntention.delete({id: row.id}).then(() => {
        this.$message({
          type: "success",
          message: "删除成功!"
        });
      });
    });
  }
}
