
import {Component, Ref, Vue, Watch} from "vue-property-decorator";
import api from "@/api";
import Tinymce from "@/components/Tinymce/index.vue";
import {
  SummaryReportCreateOrUpdateDto,
  ProjectDto,
  AttachmentHostType,
  ExternalProjectDetailDto,
  DataDictionaryDto,
  ProjectMemberItemDto,
  ExternalProjectActualBudgetItemDto, ExternalProjectSpecificPlanDto, FormType
} from "@/api/appService";
import {ElForm} from "element-ui/types/form";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import ProjectSelect from "@/components/ProjectSelect/index.vue";
import MultipleUploadFile from "@/components/MultipleUploadFile/index.vue";
import BudgetSummaryItems from "@/components/BudgetSummaryItems/index.vue";
import {v4 as uuidv4} from "uuid";
import TemporarySaving from "@/components/TemporarySaving/index.vue";
import TemporarySavingAlert from "@/components/TemporarySavingAlert/index.vue";

@Component({
  components: {
    Tinymce, SimpleUploadImage, AbSelect, ProjectSelect, MultipleUploadFile, BudgetSummaryItems,
    TemporarySaving, TemporarySavingAlert
  },
  filters: {
    formatBudgetCategory(budgetCategory: string, projectBudgetCategoryList: any[]) {
      let budget: string | undefined
      if (projectBudgetCategoryList && projectBudgetCategoryList.length > 0) {
        projectBudgetCategoryList.map((item: any) => {
          if (item.value == budgetCategory) {
            budget = item.text
            return
          }
        })
      }
      return budget
    },
  }
})
export default class CreateProjectInvite extends Vue {
  @Ref() readonly dataForm!: ElForm;
  @Ref() readonly tempSave!: TemporarySaving;
  dataId!: number;
  projectId: number = 0;
  project: ProjectDto[] = [];
  canEdit: boolean = false;
  canSelect: boolean = false;
  sexList: DataDictionaryDto[] = [];
  educationTypeList: DataDictionaryDto[] = [];

  // 项目预算明细
  budgetItems: ExternalProjectActualBudgetItemDto[] = [
    {
      projectBudgetCategory: undefined,
      projectBudgetType: undefined,
      itemName: undefined,
      projectSpecificPlanId: undefined,
      projectSpecificGoalId: undefined,
      content: undefined,
      unitPrice: undefined,
      number: undefined,
      unit: undefined,
      budgetQuotation: undefined,
      projectBudgetCapitalSource: undefined,
      budgetItemId: undefined,
      actualBudget: undefined,
      balance: undefined,
      actualPercent: undefined,
      reason: undefined,
    }
  ];

  budgetForm: SummaryReportCreateOrUpdateDto = {
    externalProjectActualBudgetItems: this.budgetItems,
    actualBudget: 0,
    balance: 0,
    actualPercent: 0,
  }

  formStr: string = '';

  form: SummaryReportCreateOrUpdateDto = {
    id: 0,
    projectId: undefined,
    projectMemberJson: undefined,
    decisionMechanism: undefined,
    partnersJson: undefined,
    projectSpecificGoalJson: undefined,
    interventions: undefined,
    implementStatus: undefined,
    implementStatusJson: undefined,
    budgetUsedJson: undefined,
    serviceObjectsFeedback: undefined,
    partnersFeedback: undefined,
    socialImpact: undefined,
    output: undefined,
    effectForSelf: undefined,
    effectForIndustry: undefined,
    problemsJson: undefined,
    executeIntrospection: undefined,
    feedbackForFoundation: undefined,
    savedAttachments: [],
    externalProjectActualBudgetItems: this.budgetItems,
    actualBudget: undefined,
    balance: undefined,
    actualPercent: undefined
  };

  // 具体计划
  specificPlans: ExternalProjectSpecificPlanDto[] = []

  get hostTypeFile() {
    return AttachmentHostType.External_Project_Summary_Report;
  }

  get formType() {
    return FormType.ClosingSelfAssessmentReport;
  }

  projectMemberItems: ProjectMemberItemDto[] = [
    {
      name: undefined,
      sex: undefined,
      age: undefined,
      educationTypeId: undefined,
      major: undefined,
      qualifications: undefined,
      projectRole: undefined,
      inputPercent: undefined
    }
  ];

  partners: any = [
    {
      name: undefined,
      resource: undefined,
      affect: undefined
    }
  ]

  externalProject: any = {
    projectPeriod: undefined,
    objectiveOfMain: undefined,
    serviceGroup: undefined
  }

  projectSpecificGoals: any = [
    {
      goals: undefined,
      goalsSummary: undefined,
      condition: undefined
    }
  ]

  budgetUsed: any = [
    {
      subject: undefined,
      planAmount: undefined,
      actualAmount: undefined,
      remark: undefined
    }
  ]

  problems: any = [
    {
      description: undefined,
      reason: undefined,
      solution: undefined
    }
  ]

  implementStatusItems: any = []

  // 项目成效及成功指标
  effects: any = [
    {
      effect: undefined,
      norm: undefined,
      validateCondition: undefined,
      result: undefined
    }
  ]

  // 项目预算种类 一级
  projectBudgetCategoryList = [];

  // 项目预算种类 二级
  projectBudgetTypeList = [];

  // 资金来源
  projectBudgetCapitalSourceList = [];
  afterProjectBudgetCategoryList: any[] = [
    {
      projectBudgetCategory: 0,
      data: []
    }
  ]
  afterProjectBudgetTypeList: any[] = [
    {
      projectBudgetType: 0,
      data: []
    }
  ]
  afterItemNameList: any[] = [
    {
      itemName: 0,
      data: []
    }
  ]
  budgetItemsDisplay: any = {}

  @Watch("projectMemberItems", {immediate: true, deep: true})
  setFormProjectMembers(newValue: any) {
    if (newValue) {
      this.form.projectMemberJson = JSON.stringify(newValue);
    }
  }

  @Watch("partners", {immediate: true, deep: true})
  setFormPartners(newValue: any) {
    if (newValue) {
      this.form.partnersJson = JSON.stringify(newValue);
    }
  }

  @Watch("projectSpecificGoals", {immediate: true, deep: true})
  setFormProjectSpecificGoals(newValue: any) {
    if (newValue) {
      this.form.projectSpecificGoalJson = JSON.stringify(newValue);
    }
  }

  @Watch("budgetUsed", {immediate: true, deep: true})
  setFormBudgetUsed(newValue: any) {
    if (newValue) {
      this.form.budgetUsedJson = JSON.stringify(newValue);
    }
  }

  @Watch("problems", {immediate: true, deep: true})
  setFormProblems(newValue: any) {
    if (newValue) {
      this.form.problemsJson = JSON.stringify(newValue);
    }
  }

  @Watch("implementStatusItems", {immediate: true, deep: true})
  setFormImplementStatusItems(newValue: any) {
    if (newValue) {
      this.form.implementStatusJson = JSON.stringify(newValue);
    }
  }


  created() {
    this.fetchEnum();
    this.fetchDataDictionary()
    this.dataId = Number(this.$route.params.id);
    if (this.dataId) {
      api.externalProjectSummaryReport
        .getDetail({id: Number(this.$route.params.id)})
        .then((res: any) => {
          this.form = {...res};

          if (this.form.projectMemberJson) {
            this.projectMemberItems = JSON.parse(this.form.projectMemberJson!);
          }
          if (this.form.partnersJson) {
            this.partners = JSON.parse(this.form.partnersJson!);
          }
          if (this.form.projectSpecificGoalJson) {
            this.projectSpecificGoals = JSON.parse(this.form.projectSpecificGoalJson!);
          }
          if (this.form.problemsJson) {
            this.problems = JSON.parse(this.form.problemsJson!);
          }
          if (this.form.budgetUsedJson) {
            this.budgetUsed = JSON.parse(this.form.budgetUsedJson!);
          }
          if (this.form.implementStatusJson) {
            this.implementStatusItems = JSON.parse(this.form.implementStatusJson!);
          }
          if (res.externalProjectActualBudgetItems && res.externalProjectActualBudgetItems.length > 0) {
            this.budgetForm.externalProjectActualBudgetItems = res.externalProjectActualBudgetItems
          }

          if (this.form.projectId) {
            api.externalProject.getDetailByProjectId({
              projectId: this.form.projectId
            }).then((res: ExternalProjectDetailDto) => {
              if (res.specificPlans && res.specificPlans.length > 0) {
                this.specificPlans = res.specificPlans;

                if (res.effects && res.effects.length > 0) {
                  this.effects = res.effects
                }
              }
              // 项目预算表格显示
              if (res.budgetItems && res.budgetItems.length > 0) {
                this.budgetItemsDisplay = res.budgetItems;
                let afterProjectBudgetCategory: any[] = [] //预算科目

                let afterProjectBudgetType: any[] = []  //预算类型
                let afterItemName: any[] = []   //子项目（活动）内容

                res.budgetItems!.forEach((item) => {

                  let beforeProjectBudgetCategory = afterProjectBudgetCategory.find(item1 => item1.projectBudgetCategory === item.projectBudgetCategory)
                  let beforeProjectBudgetType = afterProjectBudgetType.find(item1 => item1.projectBudgetType === item.projectBudgetType)
                  let beforeItemName = afterItemName.find(item1 => item1.itemName === item.itemName)

                  if (!beforeItemName) {
                    afterItemName.push({
                      itemName: item.itemName,
                      data: [item]
                    })
                  } else {
                    beforeItemName.data.push(item)
                  }

                  if (!beforeProjectBudgetType) {
                    afterProjectBudgetType.push({
                      projectBudgetType: item.projectBudgetType,
                      data: [item]
                    })
                  } else {
                    beforeProjectBudgetType.data.push(item)
                  }
                  //
                  //
                  if (!beforeProjectBudgetCategory) {
                    afterProjectBudgetCategory.push({
                      projectBudgetCategory: item.projectBudgetCategory,
                      data: [item]
                    })
                  } else {
                    beforeProjectBudgetCategory.data.push(item)
                  }

                })
                this.afterProjectBudgetCategoryList = afterProjectBudgetCategory
                this.afterProjectBudgetTypeList = afterProjectBudgetType
                this.afterItemNameList = afterItemName
                console.log(this.afterItemNameList)
              }
            })
          }
        });
    } else {
      if (this.$route.query.projectId) {
        this.canSelect = true;
        this.form.projectId = Number(this.$route.query.projectId);
        this.getProjectDetail(this.form.projectId)
      }
    }
  }

  selectProject(e: number) {
    if (!e) {
      this.clearData(e);
      return;
    }

    this.getProjectDetail(e)
  }

  getProjectDetail(projectId: number) {
    api.externalProject.getDetailByProjectId({
      projectId: projectId
    }).then((res: ExternalProjectDetailDto) => {
      if (res.specificPlans && res.specificPlans.length > 0) {
        this.clearData(projectId);
        this.specificPlans = res.specificPlans;
        res.specificPlans?.map(item => {
          const implement = {
            id: item.id,
            title: item.title,
            summary: undefined,
            peopleCount: undefined,
            planTitle: item.title,
            planSummary: item.summary,
            planPeopleCount: item.peopleCount,
            projectSpecificGoalId: item.projectSpecificGoalId,
            situation: undefined,
            info: undefined,
            canEditNew: false
          }

          this.implementStatusItems.push(implement)
        })
      }

      if (res.effects && res.effects.length > 0) {
        this.projectSpecificGoals = this.effects = res.effects
      }

      if (res.budgetItems && res.budgetItems.length > 0) {
        this.budgetForm.externalProjectActualBudgetItems = res.budgetItems;
      }

      // 项目预算表格显示
      debugger
      this.budgetItemsDisplay = res.budgetItems;
      if (res.budgetItems && res.budgetItems.length > 0) {
        let afterProjectBudgetCategory: any[] = [] //预算科目

        let afterProjectBudgetType: any[] = []  //预算类型
        let afterItemName: any[] = []   //子项目（活动）内容

        res.budgetItems!.forEach((item) => {

          let beforeProjectBudgetCategory = afterProjectBudgetCategory.find(item1 => item1.projectBudgetCategory === item.projectBudgetCategory)
          let beforeProjectBudgetType = afterProjectBudgetType.find(item1 => item1.projectBudgetType === item.projectBudgetType)
          let beforeItemName = afterItemName.find(item1 => item1.itemName === item.itemName)

          if (!beforeItemName) {
            afterItemName.push({
              itemName: item.itemName,
              data: [item]
            })
          } else {
            beforeItemName.data.push(item)
          }

          if (!beforeProjectBudgetType) {
            afterProjectBudgetType.push({
              projectBudgetType: item.projectBudgetType,
              data: [item]
            })
          } else {
            beforeProjectBudgetType.data.push(item)
          }
          //
          //
          if (!beforeProjectBudgetCategory) {
            afterProjectBudgetCategory.push({
              projectBudgetCategory: item.projectBudgetCategory,
              data: [item]
            })
          } else {
            beforeProjectBudgetCategory.data.push(item)
          }

        })
        this.afterProjectBudgetCategoryList = afterProjectBudgetCategory
        this.afterProjectBudgetTypeList = afterProjectBudgetType
        this.afterItemNameList = afterItemName
        console.log(this.afterItemNameList)
      }

      this.setFormDataOnceMore();
    })
  }

  clearData(projectId: number) {
    this.form = {
      id: this.dataId ? this.dataId : 0,
      projectId: projectId,
      projectMemberJson: undefined,
      decisionMechanism: undefined,
      partnersJson: undefined,
      projectSpecificGoalJson: undefined,
      interventions: undefined,
      implementStatus: undefined,
      implementStatusJson: undefined,
      budgetUsedJson: undefined,
      serviceObjectsFeedback: undefined,
      partnersFeedback: undefined,
      socialImpact: undefined,
      output: undefined,
      effectForSelf: undefined,
      effectForIndustry: undefined,
      problemsJson: undefined,
      executeIntrospection: undefined,
      feedbackForFoundation: undefined,
      savedAttachments: [],
    };
    this.projectMemberItems = [
      {
        name: undefined,
        sex: undefined,
        age: undefined,
        educationTypeId: undefined,
        major: undefined,
        qualifications: undefined,
        projectRole: undefined,
        inputPercent: undefined
      }
    ]

    this.partners = [
      {
        name: undefined,
        resource: undefined,
        affect: undefined
      }
    ]

    this.externalProject = {
      projectPeriod: undefined,
      objectiveOfMain: undefined,
      serviceGroup: undefined
    }

    this.projectSpecificGoals = [
      {
        goals: undefined,
        goalsSummary: undefined,
        condition: undefined
      }
    ]

    this.budgetUsed = [
      {
        subject: undefined,
        planAmount: undefined,
        actualAmount: undefined,
        remark: undefined
      }
    ]

    this.problems = [
      {
        description: undefined,
        reason: undefined,
        solution: undefined
      }
    ]

    this.implementStatusItems = []
  }

  fetchDataDictionary() {
    api.dataDictionary.getDataDictionaryListByKey({key: 'EducationType', maxResultCount: 1000})
      .then((res: any) => {
        this.educationTypeList = res.items
      })
    api.dataDictionary.getDataDictionaryListByKey({key: 'Sex', maxResultCount: 1000})
      .then((res: any) => {
        this.sexList = res.items
      })
  }

  fetchEnum() {
    api.enumService.getValues({typeName: 'ProjectBudgetCategory'}).then((res: any) => {
      this.projectBudgetCategoryList = res
    });
    api.enumService.getValues({typeName: 'ProjectBudgetType'}).then((res: any) => {
      this.projectBudgetTypeList = res
    });
    api.enumService.getValues({typeName: 'ProjectBudgetCapitalSource'}).then((res: any) => {
      this.$nextTick(() => {
        this.projectBudgetCapitalSourceList = res
      })
    });
  }

  changeBudgetForm(e: any) {
    Object.assign(this.form, this.form, e)
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        // this.form.projectMemberJson = JSON.stringify(this.projectMemberItems);
        // this.form.partnersJson = JSON.stringify(this.partners);
        // this.form.projectSpecificGoalJson = JSON.stringify(this.projectSpecificGoals);
        // this.form.budgetUsedJson = JSON.stringify(this.budgetUsed);
        // this.form.problemsJson = JSON.stringify(this.problems)
        // this.form.implementStatusJson = JSON.stringify(this.implementStatusItems)

        let canSave: boolean = true;
        if (this.form.externalProjectActualBudgetItems) {
          this.form.externalProjectActualBudgetItems.some((item: ExternalProjectActualBudgetItemDto) => {
            //debugger

            // if (!item.budgetQuotation || !item.actualBudget) {
            //   this.$message.error("资金预算明细，差异浮动25%时差异原因必填");
            //   canSave = false;
            //   return;
            // }

            if (item.budgetQuotation && (item.actualBudget! / item.budgetQuotation > 1.25 || item.actualBudget! / item.budgetQuotation < 0.75) && !item.reason) {
              this.$message.error("资金预算明细，差异浮动25%时差异原因必填");
              canSave = false;
              return;
            }
          })
        }

        if (canSave) {
          if (this.form!.id) {
            await api.externalProjectSummaryReport.update({
              body: this.form
            }).then(() => {
              this.$message.success("更新成功");
              (this.$refs.tempSave as any).deleteCaChe();
              this.$router.back();
            });
          } else {
            await api.externalProjectSummaryReport.create({
              body: this.form
            }).then(() => {
              this.$message.success("新建成功");
              (this.$refs.tempSave as any).deleteCaChe();
              this.$router.back();
            });
          }
        }
      } else {
        this.$message({
          type: 'error',
          message: '请检查表单'
        })
      }
    });
  }

  setFormData(formStr: string) {
    this.formStr = formStr;

    this.form = JSON.parse(this.formStr);
    this.projectMemberItems = JSON.parse(this.form.projectMemberJson!)
    this.partners = JSON.parse(this.form.partnersJson!);
    this.projectSpecificGoals = JSON.parse(this.form.projectSpecificGoalJson!);
    this.budgetUsed = JSON.parse(this.form.budgetUsedJson!)
    this.problems = JSON.parse(this.form.problemsJson!)
    this.implementStatusItems = JSON.parse(this.form.implementStatusJson!)
    this.budgetForm = {
      externalProjectActualBudgetItems: this.form.externalProjectActualBudgetItems,
      actualBudget: this.form.actualBudget,
      balance: this.form.balance,
      actualPercent: this.form.actualPercent,
    }
  }

  setFormDataOnceMore() {
    if (this.formStr && this.formStr.length > 0) {
      this.form = JSON.parse(this.formStr);
      this.projectMemberItems = JSON.parse(this.form.projectMemberJson!)
      this.partners = JSON.parse(this.form.partnersJson!);
      this.projectSpecificGoals = JSON.parse(this.form.projectSpecificGoalJson!);
      this.budgetUsed = JSON.parse(this.form.budgetUsedJson!)
      this.problems = JSON.parse(this.form.problemsJson!)
      this.implementStatusItems = JSON.parse(this.form.implementStatusJson!)
      this.budgetForm = {
        externalProjectActualBudgetItems: this.form.externalProjectActualBudgetItems,
        actualBudget: this.form.actualBudget,
        balance: this.form.balance,
        actualPercent: this.form.actualPercent,
      }

      this.formStr = '';
    }
  }

  cancel() {
    this.$router.back();
    // (this.$refs.dataForm as any).resetFields();
    // this.$emit("input", false);
  }

  //增加项目执行团队及人员分工
  private addRow(r: number) {
    const obj = {
      name: undefined,
      sex: undefined,
      age: undefined,
      educationTypeId: undefined,
      major: undefined,
      qualifications: undefined,
      projectRole: undefined,
      inputPercent: undefined
    };

    this.projectMemberItems = [...this.projectMemberItems!, obj];
  }

  // 删除项目执行团队及人员分工
  private deleteRow(index: number) {
    this.projectMemberItems!.splice(index, 1);
  }

  // 增加合作机构
  private addOrgRow() {
    const obj = {
      name: undefined,
      resource: undefined,
      affect: undefined
    };

    this.partners = [...this.partners!, obj];
  }

  // 删除合作机构
  private deleteOrgRow(index: number) {
    this.partners!.splice(index, 1);
  }

  //增加指标完成情况
  private addPojectSpecificGoalsRow(r: number) {
    const obj = {
      goals: undefined,
      goalsSummary: undefined,
      condition: undefined

    };

    this.projectSpecificGoals = [...this.projectSpecificGoals!, obj];
  }

  // 删除指标完成情况
  private deleteProjectSpecificGoalsRow(index: number) {
    this.projectSpecificGoals!.splice(index, 1);
  }

  //增加项目活动完成情况对照
  private addImplementStatusItemsRow(r: number) {
    const obj = {
      id: 0,
      title: undefined,
      summary: undefined,
      peopleCount: undefined,
      planTitle: undefined,
      planSummary: undefined,
      planPeopleCount: undefined,
      projectSpecificGoalId: undefined,
      situation: undefined,
      info: undefined,
      canEditNew: true
    }
    this.implementStatusItems = [...this.implementStatusItems!, obj];
  }

  // 删除项目活动完成情况对照
  private deleteImplementStatusItemsRow(index: number) {
    this.implementStatusItems!.splice(index, 1);
  }

  //增加项目经费使用情况
  private addBudgetUserdRow(r: number) {
    const obj = {
      subject: undefined,
      planAmount: undefined,
      actualAmount: undefined,
      remark: undefined
    };

    this.budgetUsed = [...this.budgetUsed!, obj];
  }

  // 删除项目经费使用情况
  private deleteBudgetUserdRow(index: number) {
    this.budgetUsed!.splice(index, 1);
  }

  //增加项目执行中的问题和建议
  private addProblemRow(r: number) {
    const obj = {
      description: undefined,
      reason: undefined,
      solution: undefined,
    };

    this.problems = [...this.problems!, obj];
  }


  // 删除项目执行中的问题和建议
  private deleteProblemRow(index: number) {
    this.problems!.splice(index, 1);
  }

  newGuid() {
    return uuidv4();
  }

  roleRule = {
    projectId: [
      {
        required: true,
        message: "项目必选",
        trigger: "blur"
      }
    ],
    reportDate: [
      {
        required: true,
        message: "提交报告日期必选",
        trigger: "blur"
      }
    ],
    serviceObjectsFeedback: [
      {
        required: true,
        message: "服务对象反馈必填",
        trigger: "blur"
      }
    ],
    partnersFeedback: [
      {
        required: true,
        message: "相关方反馈必填",
        trigger: "blur"
      }
    ],
    socialImpact: [
      {
        required: true,
        message: "社会影响必填",
        trigger: "blur"
      }
    ],
    output: [
      {
        required: true,
        message: "项目成果/产出必填",
        trigger: "blur"
      }
    ],
    effectForSelf: [
      {
        required: true,
        message: "对执行机构的作用必填",
        trigger: "blur"
      }
    ],
    effectForIndustry: [
      {
        required: true,
        message: "对领域/行业的作用必填",
        trigger: "blur"
      }
    ],
    executeIntrospection: [
      {
        required: true,
        message: "整个项目执行的反思必填",
        trigger: "blur"
      }
    ],
    feedbackForFoundation: [
      {
        required: true,
        message: "对基金会的建议和反馈必填",
        trigger: "blur"
      }
    ]
  };
}
