
import {Component, Vue} from "vue-property-decorator";
import api from "@/api";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
import ProjectUploadTitleImage from "@/views/project/common/UploadTitleImage.vue";
import ProjectPublishButton from "@/views/project/common/ProjectPublishButton.vue";
import {ExternalProjectDto} from "@/api/appService";

@Component({
  name: "externalProjectPhasesList",
  components: {
    PagedTableView,
    AgileAuditTag,
    ProjectPublishButton,
    ProjectUploadTitleImage
  },
  filters: {
    formatWorkflowStep(status: string, list: any[]) {
      let result = '';
      if (list && list.length > 0) {
        list.map((item) => {
          if (item.value == status) {
            result = item.text;
            return;
          }
        });
      }
      return result;
    }
  }
})

export default class ExternalProjectPhasesList extends Vue {
  queryForm = {
    displayName: undefined,
    year: undefined,
    isPhase: true
  }
  projectId = 0;
  workflowStepList: any = [];

  created() {
    this.fetchEnum();
  }

  fetchEnum() {
    api.enumService.getValues({typeName: 'WorkflowOfProjectByExternal'})
      .then(res => {
        this.workflowStepList = res;
      })
  }

  fetchData(params: any) {
    params.isPhase = true;
    return api.externalProject.getAll(params);
  }

  // 操作按钮
  handleMenu($row: any) {
    this.projectId = $row.item.project.id;
    switch ($row.type) {
      case 'titleImage':
        (this.$refs.projectUploadTitleImage as any).show = true;
        break;
      case 'editProjectResult':
        this.$router.push(
          {
            name: 'editProjectResult',
            params:
              {
                projectId: this.projectId.toString()
              }
          });
        break;
      case 'publishControl':
        api.project.changePublicShow({projectId: this.projectId}).then(() => {
          this.$message.success('是否公示已更新');
        });
        break;
    }
  }

  // 查看项目周期
  checkProjectPhases(row: ExternalProjectDto) {
    this.$router.push({
      name: 'externalProjectPhasesIndex',
      params: {
        id: String(row.projectId)
      }
    })
  }

  handleStop(row: ExternalProjectDto) {
    this.$confirm('确定终止该项目吗', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      api.project.terminate({projectId: row.projectId})
        .then(() => {
          this.$message({
            type: "success",
            message: "修改成功!"
          });
        })
    });
  }
}
