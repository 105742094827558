
import api from "@/api/index"; //ABP API接口
import {Vue, Component, Ref} from "vue-property-decorator";

import {
  OrganizationUnitDto,
  SummaryReportDto,
  AuditFlowScope, FormType
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import OrganizationSelect from "@/components/OrganizationSelect/index.vue";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
import SendNotify from "@/components/SendNotify/index.vue";
import {UserModule} from "@/store/modules/user";

@Component({
  name: "RoleList",
  components: {
    SendNotify,
    PagedTableView,
    OrganizationSelect,
    AgileAuditTag
  }
})
export default class ExternalProjectSummaryReportList extends Vue {
  @Ref() showNotify!: SendNotify;
  queryForm: any = {
    projectName: "",
    orgName: "",
    startDate: "",
    endDate: ""
  };

  partnerOrgList: OrganizationUnitDto[] = [];

  formType: FormType = FormType.ClosingSelfAssessmentReport;

  get getNotifyApiUrl() {
    return api.externalProjectSummaryReport.sendNotify
  }

  get canEdit() {
    return !!UserModule.roles.find(x => x === "Finance" || x === "FoundationProject");
  }

  //获取审核状态
  getAuditStatus(index: number, row: any) {
    if ((row.audit != null && row.audit >= 0) && row.auditStatus === row.audit) {//已审核
      return true;
    } else if (row.audit === -1) {//被拒绝
      return false;
    } else if (row.audit == null) {//未开始
      return false;
    } else {//审核中
      return true;
    }
  }

  created() {
    api.organizationUnit.getAll().then((res: any) => {
      this.partnerOrgList = res.items;
    });
    if (this.$route.query.projectId) {
      this.queryForm.projectId = Number(this.$route.query.projectId);
    }
  }

  // 获取表数据
  fetchData(params: any) {
    return api.externalProjectSummaryReport.getAll(params);
  }

  // 发送通知
  handleShowNotify() {
    (this.showNotify as any).isShowNotify = true
  }

  // 新建
  handleCreate() {
    this.$router.push({name: "externalProjectSummaryReportCreate"});
  }

  handleMenu(e: any) {
    switch (e.type) {
      case "detail": {
        this.jumpDetail(e.index, e.item);
        return;
      }
      case "edit": {
        this.handleEdit(e.index, e.item);
        return;
      }
      case "delete": {
        this.handleDelete(e.index, e.item);
        return;
      }
      case "startAudit": {
        this.handleStartAudit(e.index, e.item);
        return;
      }
      case 'auditFlow': {
        this.handleAuditFlow(e.index, e.item)
        break
      }

    }
  }

  // 查看详情
  jumpDetail(index: number, row: SummaryReportDto) {
    this.$router.push({
      name: "externalProjectSummaryReportDetail",
      params: {id: row.id!.toString()}
    });
  }

  // 编辑
  handleEdit(index: number, row: SummaryReportDto) {
    this.$router.push({
      name: "externalProjectSummaryReportEdit",
      params: {id: row.id!.toString()}
    })
  }

  // 删除
  async handleDelete(index: number, row: SummaryReportDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.externalProjectSummaryReport
        .delete({id: row.id})
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功!"
          });
        });
    });
  }

  // 提交审核
  async handleStartAudit(item: number, node: SummaryReportDto) {
    await api.externalProjectSummaryReport.startAudit({
      body: {
        id: node.id
      }
    }).then(() => {
      api.formCache.delCache({formType: this.formType, hostId: node.id?.toString()}).then(() => {
        this.$message.success("已提交");
      })
    });
  }

  handleAuditFlow(index: number, row: any) {
    this.$router.push({
        name: 'auditFlowDetail', params: {
          hostType: AuditFlowScope.ExternalProjectSummaryReport,
          hostId: row.id,
          auditFlowId: row.auditFlowId
        }
      }
    );
  }
}
