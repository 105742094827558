
import api from "@/api"; //ABP API接口
import {Vue, Component, Watch, Ref} from "vue-property-decorator";
import {NotifyRecordDto} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import EditLink from "@/views/project/internalMonthlySummarize/edit.vue";
import SummarizeDetail from "@/views/project/internalMonthlySummarize/detail.vue";
import moment from "moment";

@Component({
  name: "NotifyRecordList",
  components: {
    EditLink,
    PagedTableView,
    SummarizeDetail
  }
})

export default class NotifyRecordList extends Vue {
  @Ref() readonly pagedTableView!: any
  queryForm: any = {
    title: "",
    notifyType: "",
    hostId: ""
  };

  @Watch('$route')
  changeRoute(newValue: any) {
    this.queryForm.notifyType = this.$route.params.type;
    this.queryForm.hostId = this.$route.query.id;
    this.pagedTableView!.fetchData();
  }

  NotifyTypeList = [];

  editLinkId: number = 0;

  created() {
    this.queryForm.notifyType = this.$route.params.type;
    this.queryForm.hostId = this.$route.query.id;
    // this.getNotifyTypeList()
  }


  // 获取通知类型
  // getNotifyTypeList() {
  //   api.enumService.getValues({typeName: 'NotifyTemplateType'}).then((res: any) => {
  //     this.NotifyTypeList = res
  //   })
  // }

  // 获取表数据
  fetchData(params: any) {
    params.notifyType = this.$route.params.type;
    params.hostId = this.$route.query.id;
    return api.notifyRecord.getAll(params);
  }

  // 操作按钮
  handleMenu($row: any) {
    switch ($row.type) {
      case 'detail':
        this.handleDetail($row.item)
        break

      case 'delete':
        this.handleDelete($row.index, $row.item)
        break
    }
  }

  // 新建
  handleCreate() {
    this.$router.push(
      {
        name: 'externalProjectNotifyRecordCreateAssessmentPlan',
        params: {
          type: this.queryForm.notifyType
        }
      }
    )
  }

  // 删除
  async handleDelete(index: number, row: NotifyRecordDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.notifyRecord
        .delete({
          id: row.id
        })
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功!"
          });
        });
    });
  }

  // 详情
  handleDetail(row: any) {
    this.$router.push(
      {
        name: 'externalProjectNotifyRecordDetailAssessmentPlan',
        params: {
          type: this.queryForm.notifyType,
          id: row.id
        }
      }
    )
  }

}
