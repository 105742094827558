

  import {Component, Prop, Vue, Watch} from "vue-property-decorator";
  import api from '@/api/index';
  import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";
  import {
    AttachmentHostType, DataDictionaryDto,
    ProjectBasicDetailUpdateDto,
    ProjectCreateOrUpdateDto,
    ProjectDto
  } from "@/api/appService";

  @Component({name:'ProjectTypeEdit',
    components: {SimpleUploadImage}
  })
  export default class ProjectTypeEdit extends Vue{

    @Prop({required:true,default:0})
    projectId!:number;

    show:boolean = false;

    projectTypes:DataDictionaryDto[] = [];

    projectDto:ProjectBasicDetailUpdateDto = {};



    submitting = false;

    created(){

      api.dataDictionary.getDataDictionaryListByKey({key:'ProjectType',maxResultCount:65535}).then(res=>{
        this.projectTypes = res.items!;

      });

    }



    @Watch('show')
    onShowChange(value:boolean){
      if(!value){
        this.projectDto.projectTypesArrary = [];
        return;
      }
      api.project.get({id:this.projectId}).then(res=>{
        this.projectDto = {...res};
        let array = this.projectDto.projectTypes?.split(',').filter(s=>s).map(s=>s)??[];
        this.$set(this.projectDto,'projectTypesArrary',array);
        // this.projectDto.projectTypesArrary =
      })
    }


    handleSave(){
      this.submitting = true;
      api.project.updateProjectBasicDetail({body:this.projectDto}).then(res=>{
        this.$message.success('更新成功');
        this.show = false;
        this.submitting = false;
      }).catch(res=>{
        this.submitting = false;
      });
    }


  }
